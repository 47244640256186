import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";

export default function Subscribed() {
    const token = localStorage.getItem('userToken');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [loadingSeller, setLoadingSeller] = useState(false);

    const fetchSellerData = async () => {
        setLoadingSeller(true)
        try {
            const response = await fetch('/stripe/check-seller-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token}),
            });

            const result = await response.json();

            if (response.ok) {
                setStatus(result.status)
            }
        } catch (error) {
            console.error('Error during registration:', error);
        } finally {
            setLoadingSeller(false);
        }
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await fetch('/stripe/restart-account-links', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token}),
            });

            const result = await response.json();

            if (response.ok) {
                window.location.href = result.redirect;
            }
        } catch (error) {
            console.error('Error during registration:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchSellerData();
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2
        }}>
            <Stack justifyContent="center" gap={4}>
                <img src="/completed.svg" height={300}/>
                <Stack justifyContent="center" alignItems="center" maxWidth={450} gap={1} sx={{textAlign: 'center'}}>
                    <Typography variant="h2">Subscribed Successfully</Typography>
                    <Typography variant="body1" color="textSecondary" sx={{fontSize: 16}}>
                        Your subscription is active. You can now log in to the app to start using the service.
                    </Typography>
                </Stack>

                <Stack justifyContent="center" alignItems="center" maxWidth={450} gap={0} sx={{textAlign: 'center'}}>
                    {loadingSeller ? <CircularProgress sx={{mt: 1}}/> : !status ? (
                        <>
                            <Typography variant="h4">Accepting Payments Online</Typography>
                            <Typography variant="body1" color="textSecondary" sx={{fontSize: 16}}>
                                Activate online payments to securely accept payments for appointments booked
                                online.</Typography>

                            {loading ?
                                <CircularProgress sx={{mt: 1}}/>
                                :
                                <Button
                                    sx={{mt: 1}}
                                    disabled={loading}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={fetchData}
                                >
                                    Setup online payments
                                </Button>}
                        </>
                    ) : (
                        <Stack justifyContent="center" alignItems="center" maxWidth={450} gap={0}
                               sx={{textAlign: 'center'}}>
                            <Typography variant="body1" color="textSecondary" sx={{fontSize: 16}}>
                                Your account is ready to receive payments. Please check your email for instructions on
                                how to log in and manage your account.
                            </Typography>
                            <Button
                                sx={{mt: 1}}
                                disabled={loading}
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={() => window.location.href = 'https://stripe.com'}
                            >
                                Login to stripe account
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Box>
    )
}
