import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import {styled} from '@mui/material/styles';
import AppTheme from './theme/AppTheme';
import Content from "./Content";
import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API);


const Card = styled(MuiCard)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    [theme.breakpoints.up('sm')]: {
        width: '450px',
    },
    [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        padding: theme.spacing(2)
    },
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const SignUpContainer = styled(Stack)(({theme}) => ({
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
        padding: 0,
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

const plans = ['Basic', 'Premium', 'Gold'];

export default function Register(props) {
    const navigate = useNavigate();

    const location = useLocation();
    const {price_id, planName} = location.state || {};


    useEffect(() => {
        if (!price_id || !planName) {
            navigate('/');
        }
        const token = localStorage.getItem('userToken');

        // If token exists, redirect to the account page
        if (token) {
            navigate('/account'); // Redirect to the account page
        }
    }, []);

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        barberShopName: '',
        firstName: '',
        lastName: '',
        kvk: '',
        plan: planName,
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
        barberShopName: '',
        firstName: '',
        lastName: '',
        kvk: '',
    });

    const handleInputChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setErrors(prevState => ({
            ...prevState,
            [name]: '',
        }));
    };

    const handleCheckout = async (customerId) => {
        setLoading(true);

        // Call your server to create a Checkout session
        const response = await fetch('/stripe/create-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({customerId, price_id}),  // Pass customerId and other details
        });
        const {id} = await response.json();

        const stripe = await stripePromise;

        // Redirect to Stripe Checkout
        const {error} = await stripe.redirectToCheckout({sessionId: id});

        if (error) {
            console.error('Error during Checkout redirect:', error);
        }

        setLoading(false);
    };


    const handleSubmit = async (event) => {
        let hasError = false;
        const {
            email,
            password,
            barberShopName,
            firstName,
            lastName,
            kvk,
        } = formData;
        const newErrors = {
            email: '',
            password: '',
            barberShopName: '',
            firstName: '',
            lastName: '',
            kvk: '',
        };

        if (!email) {
            newErrors.email = 'E-mail is required';
            hasError = true;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Please enter a valid email address';
            hasError = true;
        }

        if (!password || password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long.';
            hasError = true;
        } else {
            newErrors.password = '';
            hasError = false;
        }

        if (!barberShopName) {
            newErrors.barberShopName = 'Barbershop name is required';
            hasError = true;
        } else {
            newErrors.barberShopName = '';
            hasError = false;
        }

        if (!firstName) {
            newErrors.firstName = 'Firstname is required';
            hasError = true;
        } else {
            newErrors.firstName = '';
            hasError = false;
        }

        if (!lastName) {
            newErrors.lastName = 'Lastname is required';
            hasError = true;
        } else {
            newErrors.lastName = '';
            hasError = false;
        }

        if (!kvk) {
            newErrors.kvk = 'Kvk is required';
            hasError = true;
        } else {
            newErrors.kvk = '';
            hasError = false;
        }

        if (hasError) {
            setErrors(newErrors);
            return; // Prevent submission if there are errors
        }


        try {
            setLoading(true);
            const formDataWithSelectedId = {...formData, type: 'barber-owner'};
            const response = await fetch('/account/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formDataWithSelectedId),
            });

            const result = await response.json();

            if (response.ok) {
                localStorage.setItem('userToken', result.token);

                handleCheckout(result.user.stripe_customer_id);

            } else {
                if (result.message === 'Email already exists.') {
                    newErrors.email = 'Email already exists.';
                    setErrors(newErrors);
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error('Error during registration:', error);
            setLoading(false);
        }

    };

    return (
        <AppTheme {...props}>
            <CssBaseline enableColorScheme/>
            <Stack
                direction="column"
                component="main"
                sx={[
                    {
                        justifyContent: 'center',
                        height: 'calc((1 - var(--template-frame-height, 0)) * 100%)',
                        minHeight: '100%',
                    },
                    (theme) => ({
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            zIndex: -1,
                            inset: 0,
                            backgroundImage:
                                'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
                            backgroundRepeat: 'no-repeat',
                            ...theme.applyStyles('dark', {
                                backgroundImage:
                                    'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
                            }),
                        },
                    }),
                ]}
            >
                <Stack
                    direction={{xs: 'column-reverse', md: 'row'}}
                    sx={{
                        justifyContent: 'center',
                        gap: {xs: 6, sm: 12},
                        p: {xs: 0, sm: 2},
                        mx: 'auto',
                    }}
                >
                    <Stack
                        direction={{xs: 'column-reverse', md: 'row'}}
                        sx={{
                            justifyContent: 'center',
                            gap: {xs: 6, sm: 12},
                            p: {xs: 0, sm: 4},
                            m: 'auto',
                        }}
                    >


                        <Content/>


                        <SignUpContainer direction="column" justifyContent="space-between">
                            <Card variant="outlined">
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    sx={{width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)'}}
                                >
                                    Sign up
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                    <Stack direction='row' gap={3}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="name">First name</FormLabel>
                                            <TextField
                                                autoComplete="name"
                                                required
                                                fullWidth
                                                id="name"
                                                placeholder="Jon Snow"
                                                onChange={(event) => handleInputChange('firstName', event.target.value)}
                                                error={errors.firstName}
                                                helperText={errors.firstName}
                                                color={errors.firstName ? 'error' : 'primary'}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="lastname">Last name</FormLabel>
                                            <TextField
                                                id="lastname"
                                                required
                                                fullWidth
                                                placeholder="Lastname"
                                                variant="outlined"
                                                onChange={(event) => handleInputChange('lastName', event.target.value)}
                                                error={errors.lastName}
                                                helperText={errors.lastName}
                                                color={errors.lastName ? 'error' : 'primary'}
                                            />
                                        </FormControl>
                                    </Stack>

                                    <Stack direction='row' gap={3}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="Barbershop">Barbershop name</FormLabel>
                                            <TextField
                                                id="Barbershop"
                                                required
                                                fullWidth
                                                placeholder="Name"
                                                variant="outlined"
                                                onChange={(event) => handleInputChange('barberShopName', event.target.value)}
                                                error={errors.barberShopName}
                                                helperText={errors.barberShopName}
                                                color={errors.barberShopName ? 'error' : 'primary'}

                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="kvk">Kvk</FormLabel>
                                            <TextField
                                                id="kvk"
                                                required
                                                fullWidth
                                                placeholder="Kvk"
                                                variant="outlined"
                                                onChange={(event) => handleInputChange('kvk', event.target.value)}
                                                error={errors.kvk}
                                                helperText={errors.kvk}
                                                color={errors.kvk ? 'error' : 'primary'}
                                            />
                                        </FormControl>
                                    </Stack>

                                    <FormControl>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            placeholder="your@email.com"
                                            name="email"
                                            autoComplete="email"
                                            variant="outlined"
                                            onChange={(event) => handleInputChange('email', event.target.value)}
                                            error={errors.email}
                                            helperText={errors.email}
                                            color={errors.email ? 'error' : 'primary'}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel htmlFor="password">Password</FormLabel>
                                        <TextField
                                            name="password"
                                            placeholder="••••••"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{
                                                autocomplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                            onChange={(event) => handleInputChange('password', event.target.value)}
                                            error={errors.password}
                                            helperText={errors.password}
                                            color={errors.password ? 'error' : 'primary'}
                                        />
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        onClick={handleSubmit}
                                    >
                                        {loading ? 'Signing up ...' : 'Sign up '}
                                    </Button>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                    <Typography sx={{textAlign: 'center'}}>
                                        Already have an account?{' '}
                                        <Link
                                            href="/material-ui/getting-started/templates/sign-in/"
                                            variant="body2"
                                            sx={{alignSelf: 'center'}}
                                        >
                                            Sign in
                                        </Link>
                                    </Typography>
                                </Box>
                            </Card>
                        </SignUpContainer>
                    </Stack>
                </Stack>
            </Stack>
        </AppTheme>
    );
}
