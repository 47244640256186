import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Container, Grid, Typography, styled, CircularProgress} from "@mui/material";
import {FiCheck} from "react-icons/fi";
import PageContainer from "../Layout/PageContainer";
import {useNavigate} from "react-router-dom";
import Stack from "@mui/material/Stack";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API);

const StyledCard = styled(Card)(({theme, variant}) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s ease-in-out",
    backgroundColor: variant === "popular" ? "#f5f5f5" : "#ffffff",
    "&:hover": {
        transform: "translateY(-10px)",
        boxShadow: theme.shadows[10]
    }
}));

const FeatureItem = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1)
}));

const StyledContainer = styled(Container)(({theme}) => ({
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
        padding: 0,
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

function getFeatures(name) {
    if (name.toLowerCase().includes("basic")) {
        return [
            "1GB storage",
            "Email support",
            "Integration with basic apps",
        ];
    } else if (name.toLowerCase().includes("premium")) {
        return [
            "10GB storage",
            "Priority email support",
            "Integration with all apps",
            "Custom add-ons",
        ];
    } else if (name.toLowerCase().includes("gold")) {
        return [
            "Unlimited storage",
            "Dedicated support",
            "All integrations",
            "Custom solutions",
            "Full analytics suite",
            "Customizable workflows",
        ];
    } else {
        return ["Standard features included"];
    }
}

function getDescription(name) {
    const lowerCaseName = name.toLowerCase();

    if (lowerCaseName.includes("gold")) {
        return "For small company";
    } else if (lowerCaseName.includes("premium")) {
        return "For medium company";
    } else if (lowerCaseName.includes("basic")) {
        return "For small company";
    }
}

export default function PricingPlans({account}) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState();

    const [plans, setPlans] = useState([]);

    const fetchPricePlans = async (planName) => {
        try {
            setLoading(true);
            const response = await fetch('/stripe/get-products', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();

            if (response.ok) {
                const formattedData = result.map((item) => {
                    // Extract just the keyword (Basic, Premium, Gold) from the name
                    let productType = "Standard"; // Default type if no match
                    if (item.name.toLowerCase().includes("gold")) {
                        productType = "Gold";
                    } else if (item.name.toLowerCase().includes("premium")) {
                        productType = "Premium";
                    } else if (item.name.toLowerCase().includes("basic")) {
                        productType = "Basic";
                    }

                    return {
                        name: productType,
                        price: `€${(item.unit_amount / 100).toFixed(2)}`,
                        description: getDescription(item.name),
                        features: getFeatures(item.name),
                        price_id: item.price_id,
                    };
                });

                const sortedData = formattedData.sort((a, b) => {
                    const order = ["Basic", "Premium", "Gold"];
                    return order.indexOf(a.name) - order.indexOf(b.name);
                });

                setPlans(sortedData)


            }
        } catch (error) {
            console.error('Error during sign in:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPricePlans();
    }, []);


    const handleChoosePlan = async (planName, price_id) => {
        if (account) {
            try {
                setLoading(true);
                const response = await fetch('/payment/change-plans', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('userToken')
                    },
                    body: JSON.stringify({planName, price_id}),
                });
                const {id} = await response.json();

                const stripe = await stripePromise;

                // Redirect to Stripe Checkout
                await stripe.redirectToCheckout({sessionId: id});

            } catch (error) {
                console.error('Error during sign in:', error);
                setLoading(false);
            }

        } else {
            navigate(`/register`, {
                state: {price_id: price_id, planName: planName}
            });
        }
    };

    return (
        <PageContainer>
            <StyledContainer maxWidth="lg" sx={{py: 8}}>
                <Box textAlign="center" mb={8}>
                    <Typography variant="h2" component="h1" gutterBottom>
                        Get an attractive price here
                    </Typography>
                    <Typography variant="h5" color="text.secondary">
                        Choose your ideal plan from our available options below.
                    </Typography>
                </Box>

                <Grid container spacing={4}>
                    {loading ?
                        <Stack alignItems="center" justifyContent="center"
                               style={{flex: 1, marginTop: '20%'}}><CircularProgress/></Stack> : (
                            plans.map((plan) => (
                                <Grid item xs={12} sm={6} md={4} key={plan.name}>
                                    <StyledCard variant={plan.variant}>
                                        <CardContent sx={{p: 4, flexGrow: 1}}>
                                            <Typography variant="h4" component="h2" gutterBottom>
                                                {plan.name}
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                component="p"
                                                gutterBottom
                                                sx={{fontWeight: "bold"}}
                                            >
                                                {plan.price}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="text.secondary"
                                                gutterBottom
                                                sx={{mb: 3}}
                                            >
                                                {plan.description}
                                            </Typography>

                                            <Box sx={{minHeight: 192}}>
                                                {plan.features.map((feature) => (
                                                    <FeatureItem key={feature}>
                                                        <FiCheck color="#4CAF50"/>
                                                        <Typography variant="body1">{feature}</Typography>
                                                    </FeatureItem>
                                                ))}
                                            </Box>
                                            <Button
                                                style={{marginTop: "auto"}}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => handleChoosePlan(plan.name, plan.price_id)}
                                                aria-label={`Choose ${plan.name} plan`}
                                            >
                                                Choose Plan
                                            </Button>

                                        </CardContent>
                                    </StyledCard>
                                </Grid>
                            ))
                        )}
                </Grid>
            </StyledContainer>
        </PageContainer>
    );
};

