import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {styled} from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import Typography from "@mui/material/Typography";
import AppTheme from "./theme/AppTheme";
import {faStar as filledStar} from '@fortawesome/free-solid-svg-icons';
import {faStar as outlineStar} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import {CircularProgress} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';


const Card = styled(MuiCard)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    [theme.breakpoints.up('sm')]: {
        width: '450px',
    },
    [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        padding: theme.spacing(2)
    },
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const SignUpContainer = styled(Stack)(({theme}) => ({
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
        padding: 0,
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

export default function Review(props) {
    const {userId, hash, rating} = useParams();
    const [reviewed, setReviewed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);

    const [hoveredRating, setHoveredRating] = useState(0);
    const [ratingStar, setRatingStar] = useState(rating ?? 1);
    const [errorLoading, setErrorLoading] = useState(false);
    const [barberName, setBarberName] = useState('');
    const [shopName, setShopName] = useState('');
    const [shopId, setShopId] = useState('');
    const [inviteId, setInviteId] = useState('');

    const [formData, setFormData] = useState({
        reviewComment: '',
        rating: ratingStar
    });

    const [errors, setErrors] = useState({
        reviewComment: '',
    });

    // Handle star click
    const handleClick = (index) => {
        setRatingStar(index);
        handleInputChange('rating', index);
    };

    // Handle mouse hover
    const handleMouseEnter = (index) => {
        setHoveredRating(index);
    };

    // Handle mouse leave
    const handleMouseLeave = () => {
        setHoveredRating(0);
    };

    const fetchReview = async () => {
        try {
            const response = await fetch('/reviews/check-review', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({userId: userId, hash: hash}),
            });

            const result = await response.json();

            if (response.ok) {
                if (!result.auth) {
                    setErrorLoading(true)
                } else {
                    if (result.hasReviewed) {
                        setReviewed(true);
                    } else {
                        setInviteId(result.inviteId);
                        setBarberName(result.barberName);
                        setShopName(result.barberShopName);
                        setShopId(result.barberShopId);
                    }
                }
            } else {
                setErrorLoading(true)
            }
        } catch (error) {
            console.error('Error during loading reviews in:', error);
            setErrorLoading(true)
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchReview();
    }, [userId]);

    const submitReview = async () => {
        let hasError = false;
        const {
            reviewComment,
        } = formData;
        const newErrors = {
            reviewComment: '',
        };

        if (reviewComment && reviewComment.length < 6) {
            newErrors.reviewComment = 'Review must be at least 6 characters long.';
            hasError = true;
        } else {
            newErrors.reviewComment = '';
            hasError = false;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }

        setLoadingSave(true)

        const newData = {...formData, shopId: shopId, userId: userId, inviteId: inviteId};

        try {
            const response = await fetch('/reviews/create-review', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newData),
            });

            const result = await response.json();

            if (response.ok) {
                if (result.success) {
                    setReviewed(true);
                }
            }
        } catch (error) {
            console.error('Error during loading reviews in:', error);
            setErrorLoading(true)
        }
        setLoadingSave(false)
    };

    const handleInputChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setErrors(prevState => ({
            ...prevState,
            [name]: '',
        }));
    };


    return (
        loading ?
            <AppTheme {...props}>
                <CssBaseline enableColorScheme/>
                <SignUpContainer direction="column" justifyContent="space-between" alignItems="center">
                    <Card variant="outlined" style={{alignItems: 'center'}}>
                        <CircularProgress/>
                    </Card>
                </SignUpContainer>
            </AppTheme> :
            errorLoading ?
                <Box>
                    <CssBaseline enableColorScheme/>
                    <SignUpContainer direction="column" justifyContent="space-between">
                        <Card variant="outlined">
                            <Typography
                                component="h1"
                                variant="h4"
                                sx={{width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)'}}
                            >
                                Not found
                            </Typography>
                        </Card>
                    </SignUpContainer>
                </Box>


                :
                reviewed ?
                    <Box>
                        <CssBaseline enableColorScheme/>
                        <SignUpContainer direction="column" justifyContent="space-between">
                            <Card variant="outlined" style={{width: '600px'}}>
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    sx={{width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center'}}
                                >
                                    Thank you for your review!
                                </Typography>
                            </Card>
                        </SignUpContainer>
                    </Box> :
                    <Box>
                        <CssBaseline enableColorScheme/>
                        <SignUpContainer direction="column" justifyContent="space-between">
                            <Card variant="outlined">
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    sx={{width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)'}}
                                >
                                    Review
                                </Typography>

                                <Stack gap={0}>
                                    <Typography variant="h6">{shopName}</Typography>
                                    <Typography variant="subtitle1" color="text.secondary">Helped
                                        by {barberName}</Typography>
                                </Stack>

                                <Stack gap={0} mt={-1}>
                                    <div className="star-rating">
                                        {[...Array(5)].map((_, index) => {
                                            const starIndex = index + 1;
                                            return (
                                                <FontAwesomeIcon
                                                    key={starIndex}
                                                    icon={starIndex <= (hoveredRating || ratingStar) ? filledStar : outlineStar}
                                                    size={'2x'}
                                                    color="#FFAA00"
                                                    style={{cursor: 'pointer', margin: '0 5px', fontWeight: '100'}}
                                                    onClick={() => handleClick(starIndex)}
                                                    onMouseEnter={() => handleMouseEnter(starIndex)}
                                                    onMouseLeave={handleMouseLeave}
                                                />
                                            );
                                        })}
                                    </div>

                                    <Box mt={3} mb={2}>
                                        <FormLabel htmlFor="message">Message</FormLabel>
                                        <TextField
                                            required
                                            fullWidth
                                            id="message"
                                            placeholder="Optional"
                                            name="message"
                                            variant="outlined"
                                            onChange={(event) => handleInputChange('reviewComment', event.target.value)}
                                            color="primary"
                                            multiline
                                            rows={4}
                                            error={errors.reviewComment}
                                            helperText={errors.reviewComment}
                                        />
                                    </Box>


                                    <AppTheme {...props}>
                                        <LoadingButton loading={loadingSave}
                                                       variant={loadingSave ? "outlined" : "contained"}
                                                       onClick={submitReview}
                                        >
                                            Submit Review
                                        </LoadingButton>
                                    </AppTheme>


                                </Stack>
                            </Card>
                        </SignUpContainer>
                    </Box>
    )
        ;
}
