import React from "react";
import {Navigate, Outlet} from 'react-router-dom'
import axios from "axios";

const PrivateRoute = ({component: Component, ...rest}) => {
    const [auth, setAuth] = React.useState(false);
    const [isTokenValidated, setIsTokenValidated] = React.useState(false);

    React.useEffect(() => {
        let token = localStorage.getItem("userToken");
        if (token) {
            axios.get('/auth', {
                headers: {
                    'x-access-token': token
                }
            }).then((response) => {
                console.log(response.data.auth)
                if (response.data.auth) {
                    setAuth(true)
                }
            }).catch((err) => {
                setAuth(false);
                localStorage.removeItem("userToken");
                console.log(err);
            }).then(() => setIsTokenValidated(true));
        } else {
            setIsTokenValidated(true); // in case there is no token
        }
    }, [])

    if (!isTokenValidated) return null;

    return auth ? <Outlet/> : <Navigate to="/login"/>
}


export default PrivateRoute
