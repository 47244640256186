import * as React from 'react';
import Box from '@mui/material/Box';
import AppTheme from "../theme/AppTheme";
import Content from "./Content";
import Header from "./Header";

export default function Main(props) {
    const {children, title} = props;

    return (
        <Box sx={{display: 'flex', height: '100%'}}>
            <AppTheme {...props}>
                <Header title={title}/>
                <Content>
                    {children}
                </Content>
            </AppTheme>
        </Box>
    );
}
