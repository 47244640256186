import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

export default function Header({title}) {
    const navigate = useNavigate();

    return (
        <>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    width: {sm: '100%'}
                }}
            >
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h6" noWrap component="div">
                        {title}
                    </Typography>
                    <Button onClick={() => {
                        localStorage.removeItem('userToken');
                        navigate('/')
                    }} variant="text" color="inherit" sx={{color: '#fff', fontWeight: 'bold'}}>Logout</Button>
                </Toolbar>
            </AppBar>
        </>
    )
}
