import {createContext, useContext, useState} from 'react';


const UserContext = createContext(undefined);

export const UserProvider = ({children}) => {
    const [user, setUser] = useState({});

    const setUserData = (userData) => {
        setUser(userData);
    };

    return (
        <UserContext.Provider value={{user, setUser, setUserData}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserData = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserData must be used within a UserProvider');
    }
    return context;
};
