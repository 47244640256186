import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import * as React from "react";
import {styled} from "@mui/material/styles";
import MuiCard from "@mui/material/Card";

const StyledBox = styled(Box)(({theme}) => ({
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
        padding: 0,
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

export default function Content({children, drawerWidth}) {

    return (
        <StyledBox
            component="main"
            sx={{flexGrow: 1, p: 3, width: {sm: '100%'}, height: '100%'}}
        >
            <Toolbar/>
            {children}
        </StyledBox>
    )
}
