import * as React from "react";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import Main from "./Layout/Main";
import {CircularProgress} from "@mui/material";
import PricingPlans from "./Components/PricingPlans";
import Subscribed from "./Components/Subscribed";


export default function Account() {
    const [loading, setLoading] = useState(true);
    const [subscribed, setSubscribed] = useState(false);

    const getUserData = async () => {
        setLoading(true);
        const response = await fetch('/account/client-stripe-details', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('userToken')
            },
        });
        const data = await response.json();
        
        await handleCheckSub(data.stripe_customer_id);
        setLoading(false);
    }


    const handleCheckSub = async (customerId) => {
        // Call your server to create a Checkout session
        const response = await fetch('/stripe/get-subscription-status', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({customerId: customerId}),
        });
        const data = await response.json();

        if (data?.subscriptionStatus === 'active') {
            setSubscribed(true);
        } else {
            setSubscribed(false);
        }
    };


    useEffect(() => {
        getUserData();
    }, []);


    return (
        <Main title="Dashboard">
            {loading ?
                <Stack justifyContent="center" alignItems="center">
                    <CircularProgress sx={{mt: 30}}/>
                </Stack>
                : subscribed ? <Subscribed/> : <PricingPlans account={true}/>}

        </Main>
    )
}
