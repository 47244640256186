// App.js
import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Account from "./Account";
import PricingPlans from "./Components/PricingPlans";
import Register from "./Register";
import Login from "./Login";
import PrivateRoute from "./Auth";
import Review from "./Review";

export default function PageRoutes() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PricingPlans/>}/>
                <Route path="/register" element={<Register/>}/>
                {/*<Route path="/account" element={<Account/>}/>*/}
                <Route path="/login" element={<Login/>}/>

                <Route path="/review/:userId/:hash/:rating" element={<Review/>}/>

                <Route path="/account" element={<PrivateRoute/>}>
                    <Route index element={<Account/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
