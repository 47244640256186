import AppTheme from "../theme/AppTheme";


export default function PageContainer({children}, props) {

    return (
        <AppTheme {...props}>
            {children}
        </AppTheme>
    )
}
