import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import {styled} from '@mui/material/styles';
import AppTheme from './theme/AppTheme';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Alert} from "@mui/lab";


const Card = styled(MuiCard)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    [theme.breakpoints.up('sm')]: {
        width: '450px',
    },
    [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        padding: theme.spacing(2)
    },
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const SignUpContainer = styled(Stack)(({theme}) => ({
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
        padding: 0,
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));


export default function Login(props) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setErrors(prevState => ({
            ...prevState,
            [name]: '',
        }));
    };


    const handleSubmit = async (event) => {
        let hasError = false;
        const {
            email,
            password,
        } = formData;
        const newErrors = {
            email: '',
            password: '',
        };

        if (!email) {
            newErrors.email = 'E-mail is required';
            hasError = true;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Please enter a valid email address';
            hasError = true;
        }

        if (!password || password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long.';
            hasError = true;
        } else {
            newErrors.password = '';
            hasError = false;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }


        try {
            setLoading(true);
            const response = await fetch('/account/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email, password}),
            });

            const result = await response.json();

            if (response.ok) {
                console.log(result.token)
                localStorage.setItem('userToken', result.token);

                navigate('/account');

            } else {
                setLoginError('Invalid credentials');
            }
        } catch (error) {
            console.error('Error during sign in:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <AppTheme {...props}>
            <CssBaseline enableColorScheme/>
            <Stack
                direction="column"
                component="main"
                sx={[
                    {
                        justifyContent: 'center',
                        height: 'calc((1 - var(--template-frame-height, 0)) * 100%)',
                        minHeight: '100%',
                    },
                    (theme) => ({
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            zIndex: -1,
                            inset: 0,
                            backgroundImage:
                                'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
                            backgroundRepeat: 'no-repeat',
                            ...theme.applyStyles('dark', {
                                backgroundImage:
                                    'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
                            }),
                        },
                    }),
                ]}
            >
                <Stack
                    direction={{xs: 'column-reverse', md: 'row'}}
                    sx={{
                        justifyContent: 'center',
                        gap: {xs: 6, sm: 12},
                        p: {xs: 0, sm: 2},
                        mx: 'auto',
                    }}
                >
                    <Stack
                        direction={{xs: 'column-reverse', md: 'row'}}
                        sx={{
                            justifyContent: 'center',
                            gap: {xs: 6, sm: 12},
                            p: {xs: 0, sm: 4},
                            m: 'auto',
                        }}
                    >

                        <SignUpContainer direction="column" justifyContent="space-between">
                            <Card variant="outlined">
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    sx={{width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)'}}
                                >
                                    Sign in
                                </Typography>
                                {loginError && (
                                    <Alert severity="error">{loginError}</Alert>
                                )}
                                <FormControl>
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        placeholder="your@email.com"
                                        name="email"
                                        autoComplete="email"
                                        variant="outlined"
                                        onChange={(event) => handleInputChange('email', event.target.value)}
                                        error={errors.email}
                                        helperText={errors.email}
                                        color={errors.email ? 'error' : 'primary'}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel htmlFor="password">Password</FormLabel>
                                    <TextField
                                        name="password"
                                        placeholder="••••••"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            autocomplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        onChange={(event) => handleInputChange('password', event.target.value)}
                                        error={errors.password}
                                        helperText={errors.password}
                                        color={errors.password ? 'error' : 'primary'}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    {loading ? 'Signing up ...' : 'Sign up '}
                                </Button>
                            </Card>
                        </SignUpContainer>
                    </Stack>
                </Stack>
            </Stack>
        </AppTheme>
    );
}
